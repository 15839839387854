import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';

const StyledblogContainer = styled.main`
  max-width: 1000px;
`;
const StyledblogHeader = styled.header`
  margin-bottom: 50px;
  .tag {
    margin-right: 10px;
  }
`;
const StyledblogContent = styled.div`
  
  font-size: var(--fz-xxl);

  margin-bottom: 100px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.5;
    color: var(--light-slate);
  }

  a {
    ${({ theme }) => theme.mixins.inlineLink};
  }

  code {
    background-color: var(--lightest-navy);
    color: var(--lightest-slate);
    border-radius: var(--border-radius);
    font-size: var(--fz-md);
    padding: 0.2em 0.4em;
  }

  pre code {
    background-color: transparent;
    padding: 0;
  }

  
`;

const blogTemplate = ({ data, location }) => {
  const { frontmatter, html } = data.blogs.edges[0].node;
  const {  blogPublishDate, blogTitle, blogUrl } = frontmatter;

  return (
    <Layout location={location}>
      <Helmet title={blogTitle} />

      <StyledblogContainer>
        <span className="breadcrumb">
          <span className="arrow">&larr;</span>
          <Link to="/blogs">All Blogs</Link>
        </span>

        <StyledblogHeader>
          <h1 className="medium-heading">{blogTitle}</h1>
          <p className="subtitle">
            <p>{blogPublishDate}</p>
            <Link target='_top' to={blogUrl}>Link</Link>
          </p>
        </StyledblogHeader>

        <StyledblogContent dangerouslySetInnerHTML={{ __html: html }} />
      </StyledblogContainer>
    </Layout>
  );
};

export default blogTemplate;

blogTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export const pageQuery = graphql`
query($blogSlug: String!){
    blogs: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/content/blogs/"}, frontmatter: {blogSlug: {eq: $blogSlug},showInBlogs: {ne: false}}}
      sort: {fields: [frontmatter___blogPosition], order: DESC}
    ) {
      edges {
        node {
          frontmatter {
            blogImage
            blogPosition
            blogTitle
            blogUrl
            blogPublishDate
            showInBlogs
            blogSlug
          }
          html
        }
      }
    }
  }
  
`;
